import $ from 'jquery';
import jQuery from 'jquery';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';

import './scss/app.scss';

window.$ = $;
window.jQuery = jQuery;

$(document).ready(function(){
	console.log('document ready');

	//Slick Slider
	$('.slider-wrap').slick({
	  dots: false,
	  infinite: true,
	  speed: 400,
	  slidesToShow: 1,
	  slidesToScroll: 1,
	  prevArrow: '.arrow-prev',
	  nextArrow: '.arrow-next'
	});
	
});